<template>
  <ion-page>
    <ion-content id="login_page">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-align-items-center ion-padding">
          <ion-col id="login_panel" size-md="6" size-lg="4" class="ion-padding">
            <div class="ion-text-center">
              <img src="@/assets/img/logo_ara_blue.svg" class="ion-padding"/>
              <p class="ion-text-center ion-padding-horizontal ion-no-margin">
                Bienvenue sur <strong>l'Appli Pro Aix Riviera</strong>, restez informé(e) de toutes les actualités de la destination.
              </p>
            </div>
            <form class="ion-margin-bottom">
              <ion-list class="ion-margin-top">
                <ion-item>
                  <ion-input type="email" v-model="credentials.email" name="email" placeholder="Adresse email"></ion-input>
                  <ion-icon :icon="icons.mail" color="medium"></ion-icon>
                </ion-item>

                <ion-item>
                  <ion-input type="password" v-model="credentials.password" name="password" placeholder="Mot de passe"></ion-input>
                  <ion-icon :icon="icons.lockClosed" color="medium"></ion-icon>
                </ion-item>
              </ion-list>

              <div class="ion-text-center ion-padding-top">
                <ion-button color="primary" class="ion-text-uppercase" @click="login()">
                  Connexion
                  <ion-icon :icon="icons.logIn" slot="end"></ion-icon>
                </ion-button>
              </div>
            </form>
            <div id="login_actions" class="ion-text-center ion-padding-bottom">
              <p class="ion-no-margin ion-padding-bottom ion-text-uppercase" >
                <ion-text color="dark">Vous n'avez pas de compte ?</ion-text>
              </p>
              <ion-button color="success" class="ion-text-uppercase ion-margin-bottom" @click="signUp()">
                Créer un compte
                <ion-icon :icon="icons.addCircle" slot="end"></ion-icon>
              </ion-button>
              <ion-button size="small" fill="clear" @click="goToResetPwd()">
                Mot de passe oublié ?
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    <ion-toast :is-open="isNotif" @didDismiss="clearNotif()" :message="notifMsg" :duration="3000" color="tertiary"></ion-toast>
    <ion-toast :is-open="isError" @didDismiss="clearError()" :message="errorMsg" :duration="3000" color="danger"></ion-toast>
    </ion-content>
  </ion-page>
</template>

<script>
import http from "@/http.js";
import {IonPage, IonContent, IonGrid, IonCol, IonRow, IonButton, IonList, IonItem, IonInput, IonIcon, IonToast, IonText} from "@ionic/vue";
import {addCircle, lockClosed, logIn, mail} from "ionicons/icons";

export default {
  name: "LoginPage",
  components: {IonPage, IonContent, IonGrid, IonCol, IonRow, IonButton, IonList, IonItem, IonInput, IonIcon, IonToast, IonText},
  data() {
    return {
      isLoading: false,
      isNotif: false,
      notifMsg: '',
      isError: false,
      errorMsg: '',
      credentials: {
        email: "",
        password: ""
      },
    };
  },
  computed: {
    icons() { return {addCircle, lockClosed, logIn, mail}; }
  },
  methods: {
    clearError() {
      this.isError = false;
    },
    clearNotif() {
      this.isNotif = false;
    },
    login() {
      return http.post("/auth/connexion.json", {
        user: {...this.credentials}
      }).then(({data, headers}) => {
        if (data.error) {
          this.error = data.error;
          throw new Error(data.error);
        } else if (!headers || !headers.authorization) {
          throw new Error("Une erreur inconnue s'est produite.");
        } else {
          localStorage.setItem("userToken", headers.authorization.replace("Bearer ", ""));
          localStorage.setItem("userProfile", JSON.stringify(data));
          return data;
        }
      }, (err) => {
        this.isError = true;
        this.errorMsg = "Il n'a pas été possible de vous authentifier.";
      }).then(() => {
        if (!this.isError) {
          this.$router.push('/');
        }
      });
    },
    signUp() {
      this.$router.push( {name: 'inscription'});
    },
    goToResetPwd() {
      this.$router.push( {name: 'resetPwd'});
    },
    // async onSubmit() {
    //   this.isLoading = true;
    //   let error = "une erreur est survenue";
    //
    //   try {
    //     await this.login(this.credentials);
    //     console.debug('login call complete');
    //   } catch (exception) {
    //     error = exception.message;
    //   }
    //
    //   if (this.currentUser.email) {
    //     console.debug('current user email is set');
    //     const redirect = (this.$route.query.redirect || "").indexOf("connexion") !== -1 ? "/" : (this.$route.query.redirect || "/");
    //     this.$router.push(redirect);
    //     // this.$notify({
    //     //   group: "global",
    //     //   type: "success",
    //     //   title: "Connexion réussie",
    //     //   text: `Bienvenue ${this.currentUser.name} !`
    //     // });
    //   } else {
    //     this.isLoading = false;
    //     // this.$refs.passwordProvider.applyResult({
    //     //   errors: [error],
    //     //   valid: false,
    //     //   failedRules: {} // should be empty since this is a manual error.
    //     // });
    //   }
    // },
    ionViewWillEnter() {
      this.credentials = {email: '', password: ''};
    }
  }
};
</script>

<style scoped lang="scss">
@media (max-width: 480px) {
  ion-col#login_panel {
    margin-top: 0;
  }
}

img {
  max-width: 180px;
}

ion-button {
  width: 200px;
}

#login_page {
  background-image: url('@/assets/img/bg_panorama.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  --background: transparent;

  ion-grid {
    height: 100%;
    display: flex;
    align-items: center;

    & > ion-row {
      width: 100%;
    }
  }
}

#login_panel {
  background-color: rgba(255, 255, 255, 0.9);
  --ion-item-background: transparent;

  #login_actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p>ion-text {
    font-size: 0.8em;
  }
}
</style>
