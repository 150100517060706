<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="home" href="/app/articles">
          <ion-icon aria-hidden="true" :icon="icons.home"/>
          <ion-label>Accueil</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="forum" href="/app/forum">
          <ion-icon aria-hidden="true" :icon="icons.chatbubbles"/>
          <ion-label>Forum</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="agenda" href="/app/agenda">
          <ion-icon aria-hidden="true" :icon="icons.calendar"/>
          <ion-label>Agenda</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="invitations" href="/app/invitations">
          <ion-icon aria-hidden="true" :icon="icons.mail"/>
          <ion-label>Invitations</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="docs" href="/app/documents">
          <ion-icon aria-hidden="true" :icon="icons.folderOpen"/>
          <ion-label>Docs</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet} from '@ionic/vue';
import {home, chatbubbles, calendar, mail, folderOpen} from 'ionicons/icons';
export default {
  components: {IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet},
  computed: {
    icons() {
      return {home, chatbubbles, calendar, mail, folderOpen};
    }
  }
}
</script>

<style scoped lang="scss">
ion-tab-bar {
  background: linear-gradient(0.25turn, var(--ion-color-primary), #21c2f9);
  --background: transparent;
  --color: white;

  ion-tab-button {
    font-weight: bold;

    --color-selected: white;
  }
}
</style>
