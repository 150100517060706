import axios from "axios";
import router from "./router";

const authHeader = request => {
  const token = localStorage.getItem("userToken");
  if (token && request.url !== '/auth/connexion.json') {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
};
const onSuccess = response => response;
const onFailure = async err => {
  const { response } = err;
  if (response && response.status === 401) {
    localStorage.setItem("userToken", '');
    localStorage.setItem("userProfile", '');
    const route = "/connexion" + (router.currentRoute.path ? `?redirect=${router.currentRoute.path}` : '');
    await router.replace(route);
    return response;
  }
  throw err;
};

const http = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  timeout: 6000
});
http.interceptors.request.use(authHeader);
http.interceptors.response.use(onSuccess, onFailure);

export default http;
