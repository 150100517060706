import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/tabs/TabsPage.vue';
import LoginPage from "@/views/auth/LoginPage.vue";
import {AuthGuard} from "@/router/auth-guard";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/app/articles'
  },
  {
    path: '/connexion',
    name: 'connexion',
    component: LoginPage
  },
  {
    path: '/inscription',
    name: 'inscription',
    component: () => import('@/views/auth/SignUpPage.vue')
  },
  {
    path: '/reset-password',
    name: 'resetPwd',
    component: () => import('@/views/auth/ResetPasswordPage.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact/ContactPage.vue')
  },
  {
    path: '/app/',
    component: TabsPage,
    beforeEnter: AuthGuard,
    children: [
      {
        path: '',
        redirect: '/app/articles'
      },
      {
        path: 'articles',
        component: () => import('@/views/tabs/PostsPage.vue')
      },
      {
        path: 'articles/:id',
        component: () => import('@/views/tabs/PostPage.vue')
      },
      {
        path: 'documents',
        component: () => import('@/views/tabs/DocumentsPage.vue')
      },
      {
        path: 'forum',
        component: () => import('@/views/tabs/ForumPage.vue'),
      },
      {
        path: "forum/compte",
        component: () => import("@/views/forum/ProfilePage.vue"),
        props: true
      },
      {
        path: "forum/messages/:slug",
        component: () => import("@/views/forum/MessagePage.vue"),
        props: true
      },
      {
        path: "forum/messages",
        component: () => import("@/views/forum/MessagesPage.vue"),
        props: true
      },
      {
        path: "forum/:slug",
        component: () => import("@/views/forum/TopicPage.vue"),
        props: true
      },
      {
        path: 'agenda',
        component: () => import('@/views/tabs/AgendaPage.vue')
      },
      {
        path: 'invitations',
        name: 'invitations',
        component: () => import('@/views/tabs/InvitationsPage.vue'),
      },
      {
        path: 'invitations/:id',
        name: 'invitation',
        component: () => import('@/views/tabs/InvitationDetailsPage.vue'),
      },
      {
        path: 'infos',
        name: 'infos',
        component: () => import('@/views/tabs/InfosPage.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
